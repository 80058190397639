<template>
  <div class="py-5">
    <div style="text-align: center">
      <h3>Filter Export</h3>
    </div>
    <br />
    <label for="">Dari</label>
    <v-date-picker class="inline-block h-full" v-model="form.date_from" mode="date">
      <template v-slot="{ inputValue, togglePopover }">
        <div>
          <input @focus="togglePopover()" :value="inputValue" class="form-control" />
        </div>
      </template>
    </v-date-picker>
    <br>
    <label for="">Sampai</label>
    <v-date-picker class="inline-block h-full" v-model="form.date_to" mode="date"  :popover="{ placement: $screens({ default: 'left-end', md: 'top-start' }) }">
      <template v-slot="{ inputValue, togglePopover }">
        <div>
          <input @focus="togglePopover()" :value="inputValue" class="form-control" />
        </div>
      </template>
    </v-date-picker>
    <br>
     <button v-on:click="exportKontak" type="button" class="btn btn-block btn-secondary text-white ">Export</button>
       <download-csv ref="exportOutgoing" :data="exportJson" name="whacenter_outgoing.csv"
                                style="display: none"></download-csv>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        form: {
          date_from: new Date(),
          date_to: new Date()
        },
		exportJson: []
      };
    },
    mounted() {

    },
    methods: {
		async exportKontak() {
                try {
					let date = await this.formatDateToUnix()
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('outgoing_export_with_range_date',date)
                        .then((response) => {
                            this.exportJson = response.data.data.outgoings
							

                            for (let i = 0; i < this.exportJson.length; i++) {
                                this.exportJson[i].jadwal = moment.utc(this.exportJson[i].jadwal).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].delivery_schedule = moment.utc(this.exportJson[i].delivery_schedule).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].created_at = moment.utc(this.exportJson[i].created_at).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].updated_at = moment.utc(this.exportJson[i].updated_at).local().format("YYYY-MM-DD HH:mm:ss")
                                if(this.exportJson[i].device!=null)
                                  this.exportJson[i].device = this.exportJson[i].device.name
                            }
                        })
                        .then(() => {
                            this.$refs.exportOutgoing.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
      formatDateToUnix() {
        let date1 = moment(this.form.date_from).format("YYYY-MM-DD 00:00:00")
        let date2 = moment(this.form.date_to).format("YYYY-MM-DD 23:59:59")

        let date_from = parseInt(moment(moment(date1).utc().format("YYYY-MM-DD HH:mm:ss")).valueOf())
        let date_to = parseInt(moment(moment(date2).utc().format("YYYY-MM-DD HH:mm:ss")).valueOf())

        return {
          date_from,
          date_to
        }
      },
     
      
    },
  };
</script>