<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Pesan Keluar</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading && device"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <label for="">Pengirim</label>
                            <input type="text" class="form-control" :value="device.name + ' (' + device.status + ')'"
                                readonly>
                            <br>
                            <label for="">Tujuan</label>
                            <input type="text" class="form-control" v-model="outgoing.to" readonly>
                            <br>
                            <label for="">Pesan</label>
                            <textarea class="form-control" cols="30" rows="10" v-model="outgoing.text" readonly />
                            <br>
                            <label for="">Jadwal Awal</label>
                            <input type="text" class="form-control" :value="convertDateToLocal(outgoing.jadwal)" readonly>
                            <br>
                            <label for="">Delay</label>
                            <input type="text" class="form-control" :value="(outgoing.delay) + ' Detik'" readonly>
                            <br>
                            <label for="">Waktu Pengiriman Aktual</label>
                            <input type="text" class="form-control" :value="convertDateToLocal(outgoing.delivery_schedule)" readonly>
                            <br>
                            <label for="">Sumber Pesan</label>
                            <input type="text" class="form-control" v-model="outgoing.source" readonly>
                            <br>
                            <label for="">Catatan Pengiriman</label>
                            <input type="text" class="form-control" v-model="outgoing.note" readonly>
                            <br>
                            <!-- <label for="">Riwayat Pengiriman Pesan</label>
                            <ul>
                                <li v-for="hist in history" v-bind:key="hist.id"><b>{{ convertDateToLocal(hist.created_at) }} - {{ hist.description }}</b></li>
                            </ul> -->
                            <!-- <br> -->
                            <label for="">Status : &nbsp;</label>
                            <span v-if="outgoing.status == 'terkirim'" class="badge badge-success">Terkirim</span>
                            <span v-else-if="outgoing.status == 'menunggu jadwal' || outgoing.status == 'dalam antrian'" class="badge badge-warning text-white">Dalam Pengiriman</span>
                            <span v-if="outgoing.status == 'gagal'" class="badge badge-danger">Gagal</span>
                            <br>
                            <button v-if="outgoing.note != 'Nomor tidak terdaftar'" v-on:click="resendOutgoing" class="btn btn-block btn-warning">Kirim Ulang</button>
                        </div>
                        <div v-else-if="!isLoading && device == null"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <label for="">Pengirim</label>
                            <input type="text" class="form-control" value="null"
                                readonly>
                            <br>
                            <label for="">Tujuan</label>
                            <input type="text" class="form-control" v-model="outgoing.to" readonly>
                            <br>
                            <label for="">Pesan</label>
                            <textarea class="form-control" cols="30" rows="10" v-model="outgoing.text" readonly />
                            <br>
                            <label for="">Jadwal Awal</label>
                            <input type="text" class="form-control" :value="convertDateToLocal(outgoing.jadwal)" readonly>
                            <br>
                            <label for="">Delay</label>
                            <input type="text" class="form-control" :value="(outgoing.delay) + ' Detik'" readonly>
                            <br>
                            <label for="">Waktu Pengiriman Aktual</label>
                            <input type="text" class="form-control" :value="convertDateToLocal(outgoing.delivery_schedule)" readonly>
                            <br>
                            <label for="">Sumber Pesan</label>
                            <input type="text" class="form-control" v-model="outgoing.source" readonly>
                            <br>
                            <label for="">Catatan Pengiriman</label>
                            <input type="text" class="form-control" v-model="outgoing.note" readonly>
                            <br>
                            <!-- <label for="">Riwayat Pengiriman Pesan</label>
                            <ul>
                                <li v-for="hist in history" v-bind:key="hist.id"><b>{{ convertDateToLocal(hist.created_at) }} - {{ hist.description }}</b></li>
                            </ul> -->
                            <!-- <br> -->
                            <label for="">Status : &nbsp;</label>
                            <span v-if="outgoing.status == 'terkirim'" class="badge badge-success">Terkirim</span>
                            <span v-else-if="outgoing.status == 'menunggu jadwal' || outgoing.status == 'dalam antrian'" class="badge badge-warning text-white">Dalam Pengiriman</span>
                            <span v-if="outgoing.status == 'gagal'" class="badge badge-danger">Gagal</span>
                            <br>
                            <button v-if="outgoing.note != 'Nomor tidak terdaftar'" v-on:click="resendOutgoing" class="btn btn-block btn-warning">Kirim Ulang</button>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
      data() {
        return {
            id: '',
            outgoing: {},
            device: {},
            history: [],
            isLoading: true,
        };
      },
      mounted() {
        this.loadData()
        this.id = this.$route.query.id
      },
      methods: {
        convertDateToLocal(date){
                return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
        },
        async loadData(){
            try{
                let id = this.$route.query.id
                let detail = await this.$store.dispatch("outgoing_detail", { id: id })
                this.outgoing = detail.data.data.outgoing
                // console.log(this.outgoing)
                this.device = this.outgoing.device
                this.history = detail.data.history
                //console.log(this.outgoing)
                if(this.outgoing.device == null)
                   this.$toast.error("Maaf Devicenya tidak ada")
                
                    this.isLoading = false
            }catch(err){
                this.$toast.error(err.response.data.message)
                this.$router.push("/outgoing")
            }
        },
        async resendOutgoing(){
            try {
                let response = await this.$store.dispatch("outgoing_resend", {
                    message_id: parseInt(this.id)
                });
                this.loadData();
                this.$toast.success(response.data.message);
            } catch (err) {
                this.$toast.error("Server error");
            }
        },
      },
    };
</script>
