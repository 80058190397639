<template>
    <div>
        <form v-on:submit.prevent="newSendProtocol" enctype="multipart/form-data">
            <div v-if="!form.multipleJadwal">
                <label for="">Pengirim</label>
                <multiselect v-model="form.sender" tag-placeholder="Pilih Device Pengirim"
                    placeholder="Pilih Device Pengirim" label="name" :custom-label="customLabel" track-by="id"
                    :options="device">
                </multiselect>
                <br>
            </div>
            <label for="">Tujuan Pesan</label>
            <select v-model="form.target_method" class="form-control">
                <option value="manual">Input Manual</option>
                <option value="contact">Group / Kontak Whacenter</option>
                <option value="group">Group Whatsapp</option>
                <!-- <option value="csv" disabled>Import CSV</option> -->
            </select>
            <br>
            <div v-if="form.target_method == 'manual'">
                <label for="">Tujuan</label>
                <input type="text" class="form-control" placeholder="08xxxxxxxxx" v-model="form.receiver" required>
            </div>
            <div v-else-if="form.target_method == 'contact'">
                <label for="">Tujuan</label>
                <multiselect v-model="multiselect.value" tag-placeholder="Cari group atau kontak"
                    placeholder="Cari group atau kontak" label="label" track-by="id" :options="multiselect.options"
                    :multiple="true" :internal-search="false" :loading="multiselect.isLoading" @search-change="loadReceiver"></multiselect>
            </div>
            <div v-else-if="form.target_method == 'group'">
                <label for="">Tujuan</label>
                <div class="alert alert-warning" role="alert" v-if="checkSender() == 0">
                    <div class="alert-content">
                        <p><b>Silahkan Pilih Device Terlebih Dahulu</b></p>
                    </div>
                </div>
                <div class="alert alert-warning" role="alert" v-else-if="checkSender() > 1">
                    <div class="alert-content">
                        <p><b>Device Tidak Boleh Lebih Dari 1</b></p>
                    </div>
                </div>
                <div v-else-if="checkSender() == 1 && checkDeviceGroups() == 0">
                    <button v-if="loading.isGroupLoading" class="btn btn-primary" disabled>
                        <div class="spinner-border spinner" role="status"></div>
                         Loading...
                    </button>
                    <button v-else type="button" v-on:click="loadGroup(form.sender)" class="btn btn-primary">Load Group</button>
                </div>
                <div v-else-if="checkSender() == 1 && checkDeviceGroups() > 0">
                    <multiselect v-model="multiselect.value" tag-placeholder="Cari group"
                    placeholder="Cari group" label="name" track-by="id" :options="deviceGroups"
                    :multiple="true"></multiselect>
                </div>
            </div>
            <br>
            <label for="">Jenis Pesan</label>
            <select v-model="form.message_type" class="form-control">
                <option value="text">Text / Media</option>
                <option value="loc">Location Message</option>
                <option value="poll">Poll</option>
                <!-- <option v-if="form.target_method != 'group'" value="button">Button Message</option>
                <option v-if="form.target_method != 'group'" value="list">List Message</option> -->
            </select>
            <br>
            <div v-if="form.message_type == 'text'">
                <label for="">Text</label>
                <CustomTextArea v-model="form.text"></CustomTextArea>
                <br>
                <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                <input ref="file" type="file" @change="onFileChange" class="form-control">
            </div>
            <div v-else-if="form.message_type == 'loc'">
                <label for="">Latitude</label>
                <input type="text" v-model="form.lat" class="form-control">
                <br>
                <label for="">Longitude</label>
                <input type="text" v-model="form.long" class="form-control">
                <br>
            </div>
            <div v-else-if="form.message_type == 'poll'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">Poll Message</h5>
                    <label for="">Pertanyaan</label>
                    <CustomTextArea v-model="form.question"></CustomTextArea>
                    <br>
                    <button type="button" class="btn btn-primary btn-sm" v-on:click="addAnswer"
                        style="margin:5px">Tambah Jawaban</button>
                    <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteAnswer"
                        style="margin:5px">Hapus Jawaban</button>

                    <br>
                    <div v-for="btn, idx in answers" v-bind:key="idx">
                        <input type="text" class="form-control mt-2" v-model="answers[idx]" :placeholder="'Jawaban ' + (idx+1)" required>
                    </div>
                    <br>
                    <label for="">Maksimal Jawaban</label>
                    <input type="number" v-model="form.max_answer" placeholder="1" class="form-control">
                </div>
            </div>
            <div v-else-if="form.message_type == 'list'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">LIST MESSAGE</h5>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <label> File (docx, xlsx, pdf, jpg, png, mp4) [Akan Dikirimkan Secara Terpisah]</label>
                            <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png"
                                class="form-control">
                            <br>
                            <label for="">Judul List Message</label>
                            <input type="text" class="form-control" v-model="list.title" placeholder="Title" required>
                            <br>
                            <label for="">Deskripsi List Message</label>
                            <CustomTextArea v-model="form.text"></CustomTextArea>
                            <br>
                            <label for="">Button Text</label>
                            <input type="text" class="form-control" v-model="list.buttonText" placeholder="Klik Disini"
                                required>
                            <br>
                            <button type="button" class="btn btn-primary btn-sm" v-on:click="addListSection"
                                style="margin:5px">Tambah Pilihan</button>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteListSection"
                                style="margin:5px">Hapus Pilihan</button>

                            <br>
                            <div v-for="ls in list.sections" v-bind:key="ls.id">
                                <br>
                                <div class="card thick-border">
                                    <div class="card-body">
                                        <label for="">Judul Pilihan</label>
                                        <input type="text" class="form-control" v-model="ls.title" placeholder="Title" required>
                                        <br>
                                        <label for="">Deskripsi Pilihan</label>
                                        <CustomTextArea v-model="ls.description"></CustomTextArea>
                                        <!-- <input type="text" class="form-control" v-model="ls.description" placeholder="Description" required> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img style="padding:15px" src="@/assets/img/listmsg/1.png" />
                            <img style="padding:15px" src="@/assets/img/listmsg/2.png" width="371px" />

                        </div>
                    </div>
                </div>
                <div style="margin: 5px">
                    <i>* File / Gambar akan dikirimkan terlebih dahulu (secara terpsisah)</i>
                </div>
            </div>
            <br>
            <input v-model="form.send_now" class="checkbox" type="checkbox" id="sendnow">
            <label for="sendnow">
                <span for="sendnow" class="checkbox-text">&nbsp;Kirim pesan sekarang</span>
            </label>
            <br>
            <br>
            <div v-if="!form.send_now">
                <!-- <input v-model="form.multipleJadwal" class="checkbox" type="checkbox" id="multipleJadwal">
                <label for="multipleJadwal">
                    <span for="multipleJadwal" class="checkbox-text">&nbsp;Aktifkan multiple jadwal <span class="badge badge-success">NEW</span>
                </span>
                </label> -->
                <br>
                <div v-if="!form.multipleJadwal">
                    <label for="">Tanggal & Jam</label>
                    <v-date-picker class="inline-block h-full" v-model="form.date" mode="dateTime" is24hr>
                        <template v-slot="{ inputValue, togglePopover }">
                            <div>
                                <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div v-else>
                    <div class="card thick-border">
                        <div class="card-body">
                            <h5 style="text-align: center;">MULTIPLE JADWAL</h5>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <a v-on:click="addJadwal" class="btn btn-block btn-success text-white" style="display: inline-flex; margin: 5px">
                                        Tambah Jadwal
                                    </a>
                                </div>
                                <div class="col-md-6">
                                    <a v-on:click="deleteJadwal" class="btn btn-block btn-danger text-white" style="display: inline-flex; margin: 5px">
                                        Hapus Jadwal
                                    </a>
                                </div>
                            </div>
                            <br>
                            <div id="var">
                                <div v-for="vars in form.multipleDate" :key="vars.id" >
                                    <div class="card thick-border">
                                        <div class="card-body">
                                            <h5 style="text-align: center;">Jadwal {{ vars.id }}</h5>
                                            <br>
                                            <label for="">Pengirim</label>
                                            <multiselect v-model="vars.sender" tag-placeholder="Pilih Device Pengirim"
                                                placeholder="Pilih Device Pengirim" label="name" :custom-label="customLabel" track-by="id"
                                                :options="device">
                                            </multiselect>
                                            <br>
                                            <label for="">Tanggal & Jam</label>
                                            <v-date-picker class="inline-block h-full" v-model="vars.date" mode="dateTime" is24hr>
                                                <template v-slot="{ inputValue, togglePopover }">
                                                    <div>
                                                        <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                            <br>
                                            <label for="">Limit</label>
                                            <input type="number" class="form-control" v-model="vars.limit" required>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>
                            <i>*Jika nomor tujuan broadcast melebihi limit yang di-setting, maka sisa nomor tujuan akan ikut dikirimkan pada jadwal terakhir</i>
                        </div>
                    </div>
                </div>

                <br>
                <br>
            </div>
            <label> Delay Interval (Detik) </label>
            <div class="row">
                <div class="col-md-5">
                    <input type="number" v-model="form.delay_start" placeholder="Dari" class="form-control">
                </div>
                <div class="col-md-2">
                    <center>
                        <b style="text-align: center;">SAMPAI</b>
                    </center>
                </div>
                <div class="col-md-5">
                    <input type="number" v-model="form.delay_end" placeholder="Sampai" class="form-control">
                </div>
            </div>
            <br>
            <label> Repeat </label>
            <select class="form-control" v-model="form.repeat">
                <option value="single" selected>Mati</option>
                <option value="hourly">Tiap Jam</option>
                <option value="daily">Harian</option>
                <option value="weekly">Mingguan</option>
                <option value="monthly">Bulanan</option>
                <option value="yearly">Tahunan</option>
            </select>
            <br>
            <div v-if="form.target_method == 'contact'">
                <i>- Anda bisa menggunakan dynamic string untuk generate random string, contoh : (halo|hai|pagi|siang)
                </i>
                <br>
                <i>- Anda bisa panggil nama kontak menggunakan {nama} </i>
                <br>
                <i>- Anda bisa panggil variabel menggunakan {var1}, {var2}, {var3}, dst </i>
                <br>
            </div>
            <div v-else>
                <br>
            </div>
            <br>
            <!-- <input v-model="form.advanceSetting" class="checkbox" type="checkbox" id="advanceSetting">
            <label for="advanceSetting">
                <span for="advanceSetting" class="checkbox-text">&nbsp;Advance Setting  <span class="badge badge-success">NEW</span></span>
            </label>
            <div v-if="form.advanceSetting">
                <div class="card thick-border">
                    <div class="card-body">
                        <label for="">Skip</label>
                        <input type="number" class="form-control" v-model="form.skip">
                        <br>
                        <label for="">Limit</label>
                        <input type="number" class="form-control" v-model="form.limit">
                    </div>
                </div>
            </div> -->
            <br>
            <br>
            <button class="btn btn-primary btn-block">Kirim Pesan</button>
        </form>
        <modal name="send_progress" :height="'auto'" :adaptive="true" :scrollable="true" style="overflow-y: auto;">
            <div style="margin: 20px;text-align:center">
                <div class="spinner-border spinner" role="status"></div>
                <br>
                <h2>{{ progress }}%</h2>
                <br>
                <h3>SEDANG MEMPERSIAPKAN PESAN</h3>
                <p>Jangan Tutup Halaman Ini</p>
            </div>
        </modal>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {
        props: ['device'],
        components: {
            CustomTextArea,
            Multiselect
        },
        data() {
            return {
                form: {
                    sender: null,
                    receiver: "",
                    text: "",
                    file: "",
                    date: moment().utcOffset(this.$store?.state?.auth?.user?.timezone).format("YYYY-MM-DD HH:mm:ss"),
                    footer: "",
                    message_type: "text",
                    target_method: "contact",
                    send_now: true,
                    delay_start: 30,
                    delay_end: 60,
                    multipleJadwal: false,
                    multipleDate: [],
                    advanceSetting: false,
                    limit: -1,
                    skip: 0,
                    question: "",
                    answers: [],
                    max_answer: 1,
                    repeat: "single"
                },
                page: {
                    one: true,
                    two: false,
                    three: false
                },
                multiselect: {
                    isLoading: true,
                    value: [],
                    options: []
                },
                progress: 0,
                contact: [],
                group: [],
                button: [],
                answers: [],
                list: {
                    title: "",
                    buttonText: "",
                    sections: [],
                    section_index: 0
                },
                button_index: 0,
                answer_index: 0,
                loading: {
                    isGroupLoading: false,
                },
                deviceGroups: []
            };
        },
        watch: {
            'form.sender' : {
                handler(){
                    this.deviceGroups = []
                },
                deep: true
            },
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });

            const dt = moment().utcOffset(this.$store?.state?.auth?.user?.timezone).format("YYYY-MM-DD HH:mm:ss")
            this.form.date = dt

            this.getBlastList(1)
        },
        methods: {
            toUtc(date = moment().utcOffset(this.$store?.state?.auth?.user?.timezone).format("YYYY-MM-DD HH:mm:ss")){
                let wilFormated = date;
                if (wilFormated instanceof Date) {
                    wilFormated = wilFormated.toISOString();
                    // console.log(wilFormated)
                }
                if (wilFormated.indexOf('Z') === -1) {
                    wilFormated = wilFormated + 'Z';
                }
                wilFormated = wilFormated.replace(' ', 'T');
                // console.log("date", date)

                // console.log("will", wilFormated)
                let utc = this.$store?.state?.auth?.user?.timezone
                return moment(wilFormated).add((-1 * utc), 'h').valueOf()
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            customLabel({
                name,
                status
            }) {
                return `${name} (${status})`
            },
            async getBlastList(limit, search = null) {
                this.multiselect.isLoading = true
                this.multiselect.options = []
                let option = []
                //let res = await this.$store.dispatch("contact_blast_list", {});
                let contact = await this.$store.dispatch("contact_list", { columnFilters: { name: search }, page:1, limit:100 })
                let group = await this.$store.dispatch("group_list", { columnFilters: { name: search }, page:1, limit:100 })

                contact = contact.data.data.contacts
                group = group.data.data.groups

                this.contact = contact
                this.group = group

                for (let i = 0; i < group.length; i++) {
                    option.push({
                        id: "g-" + group[i].id,
                        label: "[GROUP] " + group[i].name
                    })
                }

                for (let i = 0; i < contact.length; i++) {
                    option.push({
                        id: "c-" + contact[i].id,
                        label: "[CONTACT] " + contact[i].name + " (" + contact[i].number + ")"
                    })
                }
                this.multiselect.options = option
                this.multiselect.isLoading = false
            },
            loadReceiver(query){
                this.getBlastList(100, query)
            },
            resetInput() {
                // this.$refs.file.value = null;
                this.form = {
                    sender: "",
                    receiver: "",
                    text: "",
                    file: "",
                    date: "",
                    footer: "",
                    message_type: "text",
                    target_method: "contact",
                    send_now: true,
                    delay_start: 30,
                    delay_end: 60
                }
                this.list =  {
                    title: "",
                    buttonText: "",
                    sections: [],
                    section_index: 0
                }
                this.button = []
                this.button_index = 0
                this.multiselect.value = []
            },
            onFileChange(e) {
                const selectedFile = e.target.files[0];
                this.form.file = selectedFile;
            },
            addAnswer() {
                this.answers.push("")

                this.answer_index += 1

                this.$toast.success("Tambah Answer Sukses");
            },
            deleteAnswer() {
                if (this.answer_index > 0) {
                    this.answers.splice(-1)
                    this.answer_index -= 1

                    this.$toast.success("Hapus Answer Sukses");
                }
            },
            addButton() {
                this.button.push({
                    type: "quickReplyButton",
                    displayText: "",
                    url: "",
                    phoneNumber: "",
                    id: "id-" + (this.button_index + 1)
                })

                this.button_index += 1

                this.$toast.success("Tambah Button Sukses");
            },
            deleteButton() {
                if (this.button_index > 0) {
                    this.button.splice(-1)
                    this.button_index -= 1

                    this.$toast.success("Hapus Button Sukses");
                }
            },
            addListSection() {
                this.list.sections.push({
                    title: "",
                    description: "",
                    id: "id-" + (this.list.section_index + 1)
                })

                this.list.section_index += 1

                this.$toast.success("Tambah Pilihan Sukses");
            },
            deleteListSection() {
                if (this.list.section_index > 0) {
                    this.list.sections.splice(-1)
                    this.list.section_index -= 1

                    this.$toast.success("Hapus Pilihan Sukses");
                }
            },
            renderMessage(variable, text) {
                text = text.replaceAll("{nama}", variable['name'])

                if(variable?.data){
                    let variableArray = JSON.parse(variable.data ?? [])

                    for (let i = 0; i < variableArray.length; i++) {
                        const element = variableArray[i];
                        text = text.replaceAll("{var" + element.id + "}", element.value)
                    }
                }

                return this.dynamicString(text)
            },
            dynamicString(message) {
                let match;
                while ((match = message.match(/\(([^()]*)\)/)) !== null) {
                    let split = match[1].split("|");
                   // if (split.length <= 1) {
                   // return message; // Hindari pengulangan tak terbatas jika tidak ada pilihan yang valid.
                   // }
                    const randomNumber = (min, max) => {
                    min = Math.ceil(min);
                    max = Math.floor(max);
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                    };

                    let random = randomNumber(0, split.length - 1);
                    let replacer = split[random];
                    message = message.replace(match[0], replacer);
                }

                return message;
            },
            async buildSelectedContact(fileURL = null){
                try{
                    let messages = []
                    let selected = this.multiselect.value
                    for (let i = 0; i < selected.length; i++) {
                        let split = selected[i].id.split("-")
                        let selector

                        if (split[0] == "g") {
                            selector = "group"
                        } else {
                            selector = "contact"
                        }

                        let res
                        if (selector == "group") {
                            res = await this.$store.dispatch("group_list_member", {
                                group_id: split[1],
                            });
                            res = res.data.data.members ?? []
                        } else {
                            let detail = await this.$store.dispatch("contact_detail", split[1])
                            res = [detail.data.data.contact] ?? []

                            // res = await this.$store.dispatch("group_list_member", {
                            //     group_id: split[1],
                            // });
                            // res = [this.contact[split[1]]]
                        }

                        for (let i = 0; i < res.length; i++) {
                            if(!res[i]?.number){
                                continue
                            }

                            let message_to_push = {
                                message_type: this.form.message_type,
                                to: res[i].number,
                                text: await this.renderMessage(res[i], this.form.text),
                                file: fileURL,
                                footer: this.form.footer
                            }

                            if (this.form.message_type == "button") {
                                message_to_push.button = []

                                for (let i = 0; i < this.button.length; i++) {
                                    let button_to_push = {
                                        button_id: this.button[i].id.split("-")[1],
                                        button_type: this.button[i].type,
                                        display_text: this.button[i].displayText,
                                    }

                                    if (this.button[i].type == "urlButton") {
                                        button_to_push.button_action = this.button[i].url
                                    } else if (this.button[i].type == "callButton") {
                                        button_to_push.button_action = this.button[i].phoneNumber
                                    }

                                    message_to_push.button.push(button_to_push)
                                }
                            }else if(this.form.message_type == "list"){
                                message_to_push.list = {
                                    title: this.list.title,
                                    buttonText: this.list.buttonText,
                                    sections: this.list.sections
                                }
                            }else if(this.form.message_type == "loc"){
                                message_to_push.text = "[LocationMessage](" + this.form.lat + "," + this.form.long + ")"
                                this.form.text = "[LocationMessage](" + this.form.lat + "," + this.form.long + ")"
                            }else if(this.form.message_type == "poll"){
                                message_to_push.text = `[PollMessage][${this.form.question}][${this.answers.join('~')}][${this.form.max_answer}]`;
                                this.form.text = "[LocationMessage](" + this.form.lat + "," + this.form.long + ")"
                            }

                            messages.push(message_to_push)
                        }
                    }

                    return messages
                }catch(err){
                    console.log(err)
                    return []
                }
            },
            async buildSelectedGroup(fileURL = null){
                let messages = []
                let selected = this.multiselect.value
                for (let i = 0; i < selected.length; i++) {
                    let text = this.form.text
                    if(this.form.message_type == "loc"){
                        text = "[LocationMessage](" + this.form.lat + "," + this.form.long + ")"
                    }else if(this.form.message_type == "poll"){
                        text = `[PollMessage][${this.form.question}][${this.answers.join('~')}][${this.form.max_answer}]`;
                    }

                    messages.push({
                        message_type: 'text',
                        to: selected[i].id,
                        text: text,
                        file: fileURL,
                    })
                }

                return messages
            },
            async buildSelectedManual(fileURL){
                let messages = []
                let message_to_push = {
                    message_type: this.form.message_type,
                    to: this.form.receiver,
                    text: this.form.text,
                    file: fileURL,
                }

                if (this.form.message_type == "button") {
                    message_to_push.button = []

                    for (let i = 0; i < this.button.length; i++) {
                        let button_to_push = {
                            button_id: this.button[i].id.split("-")[1],
                            button_type: this.button[i].type,
                            display_text: this.button[i].displayText,
                        }

                        if (this.button[i].type == "urlButton") {
                            button_to_push.button_action = this.button[i].url
                        } else if (this.button[i].type == "callButton") {
                            button_to_push.button_action = this.button[i].phoneNumber
                        }

                        message_to_push.button.push(button_to_push)
                    }
                }else if(this.form.message_type == "list"){
                    message_to_push.list = {
                        title: this.list.title,
                        buttonText: this.list.buttonText,
                        sections: this.list.sections
                    }
                }else if(this.form.message_type == "loc"){
                    message_to_push.text = "[LocationMessage](" + this.form.lat + "," + this.form.long + ")"
                }else if(this.form.message_type == "poll"){
                    message_to_push.text = `[PollMessage][${this.form.question}][${this.answers.join('~')}][${this.form.max_answer}]`;
                }

                messages.push(message_to_push)

                return messages
            },
            async devideArray(array, limit = -1, skip = -1, delayStart, delayEnd, setDelay = true){
                limit = parseInt(limit)
                skip = parseInt(skip)
                let i = (skip == -1) ? 0 : skip
                let len = (limit == -1) ? array.length : skip + limit
                let delay = 0

                let finalArray = []
                for (i; i < len; i++) {
                    if(!array[i]) continue
                    
                    if(setDelay){
                        array[i].delay = delay
                        delay += Math.ceil(Math.random() * (parseInt(delayEnd) - parseInt(delayStart)) + parseInt(delayStart))
                    }
                    
                    finalArray.push(array[i])
                }

                return finalArray
            },
            async newSendProtocol(){
                this.progress = 0
                this.toggleModal("send_progress")

                try{
                    let fileURL

                    let newDate = this.toUtc();
                    if (this.form.date) {
                        newDate = this.toUtc(moment(this.form.date).format('YYYY-MM-DD HH:mm:ss'))
                    }
                    // if (this.form.date == "") {
                    //     console.log('cek 1')
                    //     this.form.date = this.toUtc()
                    // } else {
                    //     console.log('cek 2')
                    //     this.form.date = this.toUtc(this.form.date)
                    // }
                    // console.log(this.form.date)

                    if (this.form.file) {
                        this.progress = 15
                        const formData = new FormData();
                        formData.append('file', this.form.file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        fileURL = response.data.data.url
                    } 

                    let messages = []

                    this.progress = 50
                    if(this.form.target_method == "contact") {
                        //GET ALL CONTACT
                        messages = await this.buildSelectedContact(fileURL)
                    }else if(this.form.target_method == "group"){
                        messages = await this.buildSelectedGroup(fileURL)
                    }else if(this.form.target_method == "manual"){
                        messages = await this.buildSelectedManual(fileURL)
                    }

                    if(messages.length == 0){
                        this.$toast.error("Tidak ada tujuan pesan")
                        this.toggleModal("send_progress")
                        this.progress = 0
                        return
                    }

                    if (this.$store.state.auth.user.message_quota != -1 && messages.length > this.$store.state.auth.user.message_quota) {
                        this.$toast.error("Kuota pesan tidak mencukupi")
                        this.toggleModal("send_progress")
                        this.progress = 0
                        return
                    }

                    if(this.form.limit != -1 || this.form.skip != 0){
                        messages = await this.devideArray(messages, this.form.limit, this.form.skip, 0, 0, false)
                    }

                    this.progress = 70

                    let finalMessage = []
                    if(this.form.multipleJadwal){
                        // console.log('finalMessage 1', this.form.multipleDate)
                        let skip = 0
                        for (let i = 0; i < this.form.multipleDate.length; i++) {
                            let limit = parseInt(this.form.multipleDate[i].limit)

                            if(i == this.form.multipleDate.length - 1){
                                limit = -1
                            }
                            // console.log('this.form.multipleDate[i].date', this.form.multipleDate[i].date)
                            finalMessage.push({
                                device_id: this.form.multipleDate[i].sender.id,
                                schedule: this.toUtc(this.form.multipleDate[i].date),
                                broadcast: await this.devideArray(messages, limit, skip, this.form.delay_start, this.form.delay_end),
                                raw_text: this.form.text,
                                repeat: this.form.repeat
                            })
                            skip += limit
                        }
                    }else{
                        // console.log('finalMessage 2', this.form)
                        finalMessage.push({
                            device_id: this.form?.sender?.id,
                            schedule: newDate,
                            broadcast: await this.devideArray(messages, -1, -1, this.form.delay_start, this.form.delay_end),
                            raw_text: this.form.text,
                            repeat: this.form.repeat
                        })
                    }

                    this.progress = 90

                    let sendMessage
                    for (let i = 0; i < finalMessage.length; i++) {
                        sendMessage = await this.$store.dispatch('outgoing_send', finalMessage[i])
                    }

                    this.progress = 100
                    this.resetInput()
                    this.$toast.success(sendMessage.data.message)
                    this.toggleModal("send_progress")
                    this.$root.$emit("refresh_package", true)
                    this.progress = 0
                }catch(err){
                    console.log(err)
                    // this.resetInput()
                    this.toggleModal("send_progress")
                    this.$root.$emit("refresh_package", true)
                    this.progress = 0
                    this.$toast.error(err?.response?.data?.message)
                }
            },
            async sendSingle() {
                this.progress = 0
                this.toggleModal("send_progress")

                try {
                    const formData = new FormData();
                    let url = ""

                    if (this.form.date == "") {
                        this.form.date = this.toUtc()
                    } else {
                        this.form.date = this.toUtc(this.form.date)
                    }

                    if (this.form.file) {
                        formData.append('file', this.form.file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        url = response.data.data.url
                    }
                    this.progress = 30

                    if(this.form.multipleJadwal == true){
                        if(this.form.multipleDate.length <= 0){
                            this.$toast.error("Tidak ada jadwal pesan")
                            this.toggleModal("send_progress")
                            this.progress = 0
                            return
                        }

                        
                    }
                    let message = {
                        //device_id: this.form.sender,
                        schedule: this.form.date,
                        broadcast: []
                    }

                    let delay_int = 0

                    if (this.form.target_method == "contact") {
                        let selected = this.multiselect.value
                        let progress_counter = 50 / selected.length

                        for (let i = 0; i < selected.length; i++) {
                            let split = selected[i].id.split("-")
                            let selector

                            if (split[0] == "g") {
                                selector = "group"
                            } else {
                                selector = "contact"
                            }

                            let res

                            if (selector == "group") {
                                res = await this.$store.dispatch("group_list_member", {
                                    group_id: split[1],
                                });
                                res = res.data.data.members ?? []
                            } else {
                                res = [this.contact[split[1]]]
                            }


                            this.progress = this.progress + progress_counter
                            for (let i = 0; i < res.length; i++) {
                                if(!res[i]?.number){
                                    continue
                                }

                                let message_to_push = {
                                    message_type: this.form.message_type,
                                    to: res[i].number,
                                    text: await this.renderMessage(res[i], this.form.text),
                                    file: url,
                                    delay: delay_int,
                                    footer: this.form.footer
                                }

                                if (this.form.message_type == "button") {
                                    message_to_push.button = []

                                    for (let i = 0; i < this.button.length; i++) {
                                        let button_to_push = {
                                            button_id: this.button[i].id.split("-")[1],
                                            button_type: this.button[i].type,
                                            display_text: this.button[i].displayText,
                                        }

                                        if (this.button[i].type == "urlButton") {
                                            button_to_push.button_action = this.button[i].url
                                        } else if (this.button[i].type == "callButton") {
                                            button_to_push.button_action = this.button[i].phoneNumber
                                        }

                                        message_to_push.button.push(button_to_push)
                                    }
                                }else if(this.form.message_type == "list"){
                                    message_to_push.list = {
                                        title: this.list.title,
                                        buttonText: this.list.buttonText,
                                        sections: this.list.sections
                                    }
                                }

                                message.broadcast.push(message_to_push)

                                delay_int += Math.ceil(Math.random() * (parseInt(this.form.delay_end) - parseInt(
                                    this.form.delay_start)) + parseInt(this.form.delay_start))
                            }
                        }
                    } else if (this.form.target_method == "manual") {
                        let message_to_push = {
                            message_type: this.form.message_type,
                            to: this.form.receiver,
                            text: this.form.text,
                            file: url,
                            delay: delay_int,
                        }

                        if (this.form.message_type == "button") {
                            message_to_push.button = []

                            for (let i = 0; i < this.button.length; i++) {
                                let button_to_push = {
                                    button_id: this.button[i].id.split("-")[1],
                                    button_type: this.button[i].type,
                                    display_text: this.button[i].displayText,
                                }

                                if (this.button[i].type == "urlButton") {
                                    button_to_push.button_action = this.button[i].url
                                } else if (this.button[i].type == "callButton") {
                                    button_to_push.button_action = this.button[i].phoneNumber
                                }

                                message_to_push.button.push(button_to_push)
                            }
                        }else if(this.form.message_type == "list"){
                            message_to_push.list = {
                                title: this.list.title,
                                buttonText: this.list.buttonText,
                                sections: this.list.sections
                            }
                        }

                        message.broadcast.push(message_to_push)
                    }else if(this.form.target_method == "group"){
                        let selected = this.multiselect.value
                        for (let i = 0; i < selected.length; i++) {
                            message.broadcast.push({
                                message_type: 'text',
                                to: selected[i].id,
                                text: this.form.text,
                                file: url,
                                delay: delay_int,
                            })
                        }
                    }

                    if(message.broadcast.length == 0){
                        this.$toast.error("Tidak ada tujuan pesan")
                        this.toggleModal("send_progress")
                        this.progress = 0
                        return
                    }

                    let finalMessage = []

                    //COUNT MESSAGE
                    let broadcastMessage = message.broadcast
                    let count = message.broadcast.length
                    
                    if (this.$store.state.auth.package.message_quota != -1 && count > this.$store.state.auth.package
                        .message_quota) {
                        this.$toast.error("Kuota pesan tidak mencukupi")
                        this.toggleModal("send_progress")
                        this.progress = 0
                        return
                    }

                    if (count > 1) {
                        let sizePerDevice = Math.ceil(count / this.form.sender.length)
                        let chunkArray = this.chunkArrayInGroups(broadcastMessage, sizePerDevice)

                        for (let i = 0; i < this.form.sender.length; i++) {
                            finalMessage.push({
                                device_id: this.form?.sender[i]?.id,
                                schedule: this.form.date,
                                broadcast: chunkArray[i],
                                raw_text: this.from.text,
                                repeat: this.form.repeat
                            })
                        }
                    } else {
                        finalMessage.push({
                            device_id: this.form?.sender[0].id,
                            schedule: this.form.date,
                            broadcast: message.broadcast,
                            raw_text: this.from.text,
                            repeat: this.form.repeat
                        })
                    }

                    let sendMessage
                    for (let i = 0; i < finalMessage.length; i++) {
                        sendMessage = await this.$store.dispatch('outgoing_send', finalMessage[i])
                    }

                    this.progress = 100
                    this.resetInput()
                    this.$toast.success(sendMessage.data.message)
                    this.toggleModal("send_progress")
                    this.$root.$emit("refresh_package", true)
                    this.progress = 0
                } catch (err) {
                    this.resetInput()
                    this.toggleModal("send_progress")
                    this.$root.$emit("refresh_package", true)
                    this.progress = 0
                    this.$toast.error(err?.response?.data?.message)
                }
            },
            chunkArrayInGroups(arr, size) {
                var myArray = [];
                for (let i = 0; i < arr.length; i += size) {
                    myArray.push(arr.slice(i, i + size));
                }

                let lastDelay = []
                for (let i = 0; i < myArray.length; i++) {
                    lastDelay.push(myArray[i][myArray[i].length - 1].delay)
                }

                for (let i = 0; i < myArray.length; i++) {
                    if (i != myArray.length - 1) {
                        for (let j = 0; j < myArray[i + 1].length; j++) {
                            myArray[i + 1][j].delay -= lastDelay[i]
                        }
                    }
                }

                return myArray;
            },
            checkSender(){
                return this.form.sender ? true : false
            },
            checkDeviceGroups(){
                return this.deviceGroups.length
            },
            async loadGroup(device){
                this.loading.isGroupLoading = true
                try{
                    this.deviceGroups = []
                    let res = await this.$store.dispatch("contact_load_group_device", {
                        id: parseInt(device.id)
                    });
                    
                    this.deviceGroups = res.data.data.groups
                    for (let i = 0; i < this.deviceGroups.length; i++) {
                        this.deviceGroups[i].name = this.deviceGroups[i].name + " (" + this.deviceGroups[i].id + ")"
                    }
                    this.$toast.success("Load Group Sukses")
                }catch(err){
                    this.$toast.error("Load Group Gagal")
                }
                this.loading.isGroupLoading = false
            },
            addJadwal() {
                // console.log('addJadwal')
                this.form.multipleDate.push({
                    id: this.form.multipleDate.length + 1,
                    sender: this.form.sender,
                    date: moment().utcOffset(this.$store?.state?.auth?.user?.timezone).format("YYYY-MM-DD HH:mm:ss"),
                    limit: null
                });
            },
            deleteJadwal() {
                this.form.multipleDate.splice(-1)
            },
        },
    };
</script>

<style scoped>

</style>

<style scoped>
    .thick-border {
        border: 2px solid #6c6c6c !important;
    }
</style>
