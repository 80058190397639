<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Edit Blast</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="device.length > 0">
                <EditBlast :device="device"></EditBlast>
            </div>
            <div v-else>
                <p>Device tidak ditemukan</p>
            </div>
        </div>
    </div>
</template>

<script>
    //import SendSingle from "@/components/forms/outgoing/SendSingle.vue";
    import EditBlast from "@/components/forms/outgoing/EditBlast.vue";

    import moment from 'moment'

    export default {
        components: {
            //SendSingle,
            EditBlast,
        },
        data() {
            return {
                messageQuota: 0,
                form: {
                    sender: "",
                    receiver: "",
                    text: "",
                    file: "",
                    date: "",
                    time: "",
                    send_now: false,
                },
                page: {
                    one: false,
                    two: true,
                    three: false
                },
                device: [],
                timezone: "",
                now: "00:00:00",
                interval: null,
            };
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fnRefreshTable);
            this.$root.$off("toggle_modal", this.fnToggleModal);

            clearInterval(this.interval)
        },
        mounted() {
            this.interval = setInterval(() => {
                this.now = moment().format("YYYY-MM-DD HH:mm:ss")
            }, 1000)

            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            // this.fnRefreshTable = () => {
            //     this.fetchPacakge();
            // }
            this.$root.$on("refresh_table", this.fnRefreshTable);
            

            this.getDevice({})
            this.getTimeZone()
            // this.fetchPacakge()

            // this.messageQuota = this.$store.state.auth.package.message_quota
        },
        methods: {
            getTimeZone() {
                var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
                this.timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            // async fetchPacakge() {
            //     try {
            //         let p = await this.$store.dispatch('user_myPackage')
            //         p = p.data.data.user_package
            //         this.messageQuota = p.message_quota
            //     } catch (err) {
            //         console.log(err)
            //     }
            // },
            changePage(page){
                this.page.one = false
                this.page.two = false
                this.page.three = false

                if(page == "one") this.page.one = true
                if(page == "two") this.page.two = true
                if(page == "three") this.page.three = true
            }
        },
    };
</script>

<style scoped>
    .nav-link{
        cursor: pointer !important;
    }
</style>