<template>
    <div>
      <div style="text-align: center">
        <h3>Action</h3>
      </div>
      <br />
      <label for="">Dari</label>
      <v-date-picker class="inline-block h-full" v-model="form.date_from" mode="date">
        <template v-slot="{ inputValue, togglePopover }">
          <div>
            <input @focus="togglePopover()" :value="inputValue" class="form-control" />
          </div>
        </template>
      </v-date-picker>
      <br>
      <label for="">Sampai</label>
      <v-date-picker class="inline-block h-full" v-model="form.date_to" mode="date">
        <template v-slot="{ inputValue, togglePopover }">
          <div>
            <input @focus="togglePopover()" :value="inputValue" class="form-control" />
          </div>
        </template>
      </v-date-picker>
      <br>
      <label> Device Pengganti </label>
      <select v-model="form.device_id" class="form-control" required>
          <option value="" selected disabled>== PILIH DEVICE ==</option>
          <option v-for="d in device" :key="d.id" :value="d.id">
              {{ d.name + " (" + d.status + ")"}}</option>
      </select>
      <br>

      <!-- <button type="button" class="btn btn-block btn-warning text-white">Kirim Ulang Pesan (Dengan Device Yang
        Terkoneksi)</button>
      <hr style="margin-top: 15px;margin-bottom: 15px;"> -->
      <button v-on:click="changeMenungguJadwal" type="button" class="btn btn-block btn-info text-white">Ganti Device Pesan "Menunggu Jadwal / Dalam Pengiriman"</button>
      <button v-on:click="changeMenungguDeviceTerkoneksi" type="button" class="btn btn-block btn-info text-white">Ganti Device Pesan "Menunggu Device Terkoneksi"</button>
      <button v-on:click="changeGagal" type="button" class="btn btn-block btn-info text-white">Ganti Device Pesan "Gagal"</button>
    </div>
  </template>
  
  <script>
    import moment from 'moment'
  
    export default {
      data() {
        return {
          form: {
            date_from: new Date(),
            date_to: new Date(),
            device_id: ""
          },
          device: []
        };
      },
      mounted() {
        this.getDevice({
            status: "CONNECTED"
        })
      },
      methods: {
        async getDevice(param) {
            let res = await this.$store.dispatch("device_list_all", param);

            for (let i = 0; i < res.data.data.devices.length; i++) {
                const element = res.data.data.devices[i];
                
                if(element.status == "CONNECTED"){
                    this.device.push(element)
                }
            }
        },
        formatDateToUnix() {
          let date1 = moment(this.form.date_from).format("YYYY-MM-DD 00:00:00")
          let date2 = moment(this.form.date_to).format("YYYY-MM-DD 23:59:59")
  
          let date_from = parseInt(moment(moment(date1).utc().format("YYYY-MM-DD HH:mm:ss")).valueOf())
          let date_to = parseInt(moment(moment(date2).utc().format("YYYY-MM-DD HH:mm:ss")).valueOf())
  
          return {
            date_from,
            date_to
          }
        },
        async changeMenungguJadwal() {
            if(this.form.device_id == ""){
                this.$toast.error("Mohon isi device pengganti");
                return
            }
            try {
                let date = await this.formatDateToUnix()
                let response = await this.$store.dispatch('resend_menunggu_jadwal', {device_id: this.form.device_id, ...date})
                this.$toast.success(response.data.message)
                this.$root.$emit("toggle_modal", "action_resend")
                this.$root.$emit("refresh_table", true)
            } catch (err) {
                console.log(err)
                this.$toast.error(err.response.data.message);
            }
        },
        async changeMenungguDeviceTerkoneksi() {
            if(this.form.device_id == ""){
                this.$toast.error("Mohon isi device pengganti");
                return
            }
            try {
                let date = await this.formatDateToUnix()
                let response = await this.$store.dispatch('resend_menunggu_device_terkoneksi', {device_id: this.form.device_id, ...date})
                this.$toast.success(response.data.message)
                this.$root.$emit("toggle_modal", "action_resend")
                this.$root.$emit("refresh_table", true)
            } catch (err) {
                console.log(err)
                this.$toast.error(err.response.data.message);
            }
        },
        async changeGagal() {
            if(this.form.device_id == ""){
                this.$toast.error("Mohon isi device pengganti");
                return
            }
            try {
                let date = await this.formatDateToUnix()
                let response = await this.$store.dispatch('resend_gagal', {device_id: this.form.device_id, ...date})
                this.$toast.success(response.data.message)
                this.$root.$emit("toggle_modal", "action_resend")
                this.$root.$emit("refresh_table", true)
            } catch (err) {
                console.log(err)
                this.$toast.error(err.response.data.message);
            }
        },
      },
    };
  </script>