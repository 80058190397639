<template>
  <div>
    <div style="text-align: center">
      <h3>Action</h3>
    </div>
    <br />
    <label for="">Dari</label>
    <v-date-picker class="inline-block h-full" v-model="form.date_from" mode="date">
      <template v-slot="{ inputValue, togglePopover }">
        <div>
          <input @focus="togglePopover()" :value="inputValue" class="form-control" />
        </div>
      </template>
    </v-date-picker>
    <br>
    <label for="">Sampai</label>
    <v-date-picker class="inline-block h-full" v-model="form.date_to" mode="date">
      <template v-slot="{ inputValue, togglePopover }">
        <div>
          <input @focus="togglePopover()" :value="inputValue" class="form-control" />
        </div>
      </template>
    </v-date-picker>
    <br>
    <!-- <button type="button" class="btn btn-block btn-warning text-white">Kirim Ulang Pesan (Dengan Device Yang
      Terkoneksi)</button>
    <hr style="margin-top: 15px;margin-bottom: 15px;"> -->
    <button v-on:click="deleteAllMessage" type="button" class="btn btn-block btn-danger text-white">Hapus Semua
      Pesan</button>
    <button v-on:click="deleteFailedMessage" type="button" class="btn btn-block btn-danger text-white">Hapus Semua Pesan
      Gagal</button>
    <button v-on:click="deletePendingMessage" type="button" class="btn btn-block btn-danger text-white">Hapus Semua
      Pesan Menunggu Jadwal</button>
    <button v-on:click="deleteWaitingMessage" type="button" class="btn btn-block btn-danger text-white">Hapus Semua
      Pesan Menunggu Device Terkoneksi</button>    
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        form: {
          date_from: new Date(),
          date_to: new Date()
        },
      };
    },
    mounted() {

    },
    methods: {
      formatDateToUnix() {
        let date1 = moment(this.form.date_from).format("YYYY-MM-DD 00:00:00")
        let date2 = moment(this.form.date_to).format("YYYY-MM-DD 23:59:59")

        let date_from = parseInt(moment(moment(date1).utc().format("YYYY-MM-DD HH:mm:ss")).valueOf())
        let date_to = parseInt(moment(moment(date2).utc().format("YYYY-MM-DD HH:mm:ss")).valueOf())

        return {
          date_from,
          date_to
        }
      },
      async deleteAllMessage() {
        try {
          let date = await this.formatDateToUnix()
          let response = await this.$store.dispatch('outgoing_delete_all', date)
          this.$toast.success(response.data.message)
          this.$root.$emit("toggle_modal", "action_outgoing")
          this.$root.$emit("refresh_table", true)
        } catch (err) {
          console.log(err)
          this.$toast.error(err.response.data.message);
        }
      },
      async deleteFailedMessage() {
        try {
          let date = await this.formatDateToUnix()
          let response = await this.$store.dispatch('outgoing_delete_failed', date)
          this.$toast.success(response.data.message)
          this.$root.$emit("toggle_modal", "action_outgoing")
          this.$root.$emit("refresh_table", true)
        } catch (err) {
          console.log(err)
          this.$toast.error(err.response.data.message);
        }
      },
      async deletePendingMessage() {
        try {
          let date = await this.formatDateToUnix()
          let response = await this.$store.dispatch('outgoing_delete_pending', date)
          this.$toast.success(response.data.message)
          this.$root.$emit("toggle_modal", "action_outgoing")
          this.$root.$emit("refresh_table", true)
        } catch (err) {
          console.log(err)
          this.$toast.error(err.response.data.message);
        }
      },
      async deleteWaitingMessage() {
        try {
          let date = await this.formatDateToUnix()
          let response = await this.$store.dispatch('outgoing_delete_waiting', date)
          this.$toast.success(response.data.message)
          this.$root.$emit("toggle_modal", "action_outgoing")
          this.$root.$emit("refresh_table", true)
        } catch (err) {
          console.log(err)
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
</script>