<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Pesan Blast</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading && device"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <label for="">Dari</label>
                            <input type="text" class="form-control" :value="blast.device.name"
                                readonly>
                            <br>

                            <label for="">Jumlah Pesan</label>
                            <input type="text" class="form-control" :value="`${countSerialize(blast.to)} Pesan`"
                                readonly>
                            <br>

                            <label for="">Pesan</label>
                            <input type="text" class="form-control" :value="blast.message"
                                readonly>
                                
                            <div v-if="blast.message_type">
                                <br>
                                <div v-if="blast.message_type == 'text'">
                                    <label for="">Pesan</label>
                                    <input type="text" class="form-control" :value="blast.message" readonly>
                                    <div v-if="blast.file">
                                        <br>
                                        <div class="card">
                                            <div class="card-body">
                                                <center>
                                                    <b>Document</b>
                                                    <br>
                                                    <a :href="blast.file" target="_blank"> Open File </a>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="blast.message_type == 'button'">
                                    <div class="card thick-border">
                                        <div class="card-body">
                                            <h6 style="text-align: center;">BUTTON MESSAGE</h6>
                                            <hr>
                                            {{ cutString(blast.text) }}
                                            <br>
                                            <div v-if="blast.file">
                                                <br>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <center>
                                                            <b>Document</b>
                                                            <br>
                                                            <a :href="blast.file" target="_blank"> Open File </a>
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div v-for="button in blast.parsedButton" v-bind:key="button.index">
                                                <div class="card thick-border">
                                                    <div class="card-body" style="padding:10px">
                                                        <div v-if="button.button_type == 'quickReplyButton'">
                                                            <!-- <p style="font-size:10px" class="float-right">[QUICK REPLY BUTTON]</p>
                                                            <br> -->
                                                            <h6 style="text-align:center">
                                                                {{ button.display_text }}
                                                            </h6>
                                                        </div>
                                                        <div v-else-if="button.button_type == 'urlButton'">
                                                            <!-- <p style="font-size:10px" class="float-right">[URL BUTTON]</p>
                                                            <br> -->
                                                            <h6 style="text-align:center">
                                                                <a :href="button.button_action" target="_blank"><i class="fas fa-globe fs-16"></i>&nbsp;{{ button.display_text }}</a>
                                                            </h6>
                                                        </div>
                                                        <div v-else-if="button.button_type == 'callButton'">
                                                            <!-- <p style="font-size:10px" class="float-right">[CALL BUTTON]</p>
                                                            <br> -->
                                                            <h6 style="text-align:center">
                                                                <a :href="'tel:' +button.button_action"><i class="fas fa-phone fs-16"></i>&nbsp;{{ button.display_text }}</a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                            <a style="color:black;font-size: 12px;">{{ blast.footer }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="blast.message_type == 'list'">
                                    <div class="card thick-border">
                                        <div class="card-body">
                                            <h6 style="text-align: center;">LIST MESSAGE</h6>
                                            <hr>
                                            <b>{{ blast.parsedList.title }}</b>
                                            <p>{{ cutString(blast.text) }}</p>
                                            <button class="btn btn-outline-success btn-block">{{ blast.parsedList.buttonText }}</button>
                                            <br>
                                            <div v-for="s in blast.parsedList.sections" v-bind:key="s.index" style="padding-top:10px">
                                                <div class="card thick-border">
                                                    <div class="card-body" style="padding:5px">
                                                        <b>{{ s.title }}</b>
                                                        <p>{{ s.description }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>

                            <label for="">Media</label>
                            <input type="text" class="form-control" :value="blast.media"
                                readonly>
                            <br>

                            <label for="">Jadwal</label>
                            <input type="text" class="form-control" :value="blast.jadwal"
                                readonly>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
      data() {
        return {
            id: '',
            blast: {},
            device: {},
            history: [],
            isLoading: true,
        };
      },
      mounted() {
        this.loadData()
        this.id = this.$route.query.id
      },
      methods: {
        convertDateToLocal(date){
                return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
        },
        async loadData(){
            try{
                let id = this.$route.query.id
                let detail = await this.$store.dispatch("broadcast_detail", { id: id })
                this.blast = detail.data.data.blast
                // console.log(this.blast)
                this.isLoading = false
            }catch(err){
                this.$toast.error(err.response.data.message)
                // this.$router.push("/blast")
            }
        },
        async resendblast(){
            try {
                let response = await this.$store.dispatch("blast_resend", {
                    message_id: parseInt(this.id)
                });
                this.loadData();
                this.$toast.success(response.data.message);
            } catch (err) {
                this.$toast.error("Server error");
            }
        },
        countSerialize(str){
            const match = str.match(/a:(\d+):/);
            const number = match ? match[1] : 0;

            return number;      
        }
      },
    };
</script>
